import {Injectable, ErrorHandler, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {GlobalVariables} from "../../global-variables";
import {AlertController, Platform} from "@ionic/angular";
import {EmailComposer} from '@ionic-native/email-composer/ngx';

declare var Email: any;

@Injectable({
    providedIn: 'root'
})
export class LogService {

    error = [];
    logOfConsole = [];
    sendIsActive = false;

    constructor(
        public gv: GlobalVariables,
        public platform: Platform,
        public email: EmailComposer,
        public alertCtrl: AlertController,
        public ngZone: NgZone
    ) {

    }

    connect() {
        const self = this;
        (function () {
            var exLog = console.log;
            console.log = function (msg) {
                exLog.apply(console, arguments);
                self.gv.__ERROR.push({
                    type: 'LOG',
                    error: msg,
                    arg: arguments,
                    console: console
                });
            }
            var exWarn = console.warn;
            console.warn = function (msg) {
                exWarn.apply(console, arguments);
                self.gv.__ERROR.push({
                    type: 'WARN',
                    error: msg,
                    arg: arguments,
                    console: console
                });
            }
            var exError = console.error;
            console.error = function (msg) {
                exError.apply(console, arguments);
                self.gv.__ERROR.push({
                    type: 'ERROR',
                    error: msg,
                    arg: arguments,
                    console: console
                });
            }
        })();
    }

    serverError(error: any) {
        if (error.meta == undefined) {
            this.gv.__ERROR.push({
                type: 'SERVER ERROR',
                error: error,
                arg: null,
                console: null
            });
        } else {
            this.gv.__ERROR.push({
                type: 'SERVER OK',
                error: error,
                arg: null,
                console: null
            });
        }
    }

    sendLOG() {
        const self = this;
        this.gv.__ERROR.push({
            type: 'LOCAL STORAGE',
            error: null,
            arg: null,
            console: window.localStorage
        });
        this.gv.__ERROR.push({
            type: 'SESSION STORAGE',
            error: null,
            arg: null,
            console: window.sessionStorage
        });
        this.sendIsActive = true;
        switch (this.gv.__GLOBAL_PLATFORM) {
            case 'cordova':
                let stringArrayHTML = '';
                self.ngZone.run(() => {
                    setTimeout(() => {
                        for (var i in self.gv.__ERROR) {
                            stringArrayHTML = stringArrayHTML + '<div>' + i + ' ---------------------------------</div>'
                            stringArrayHTML = stringArrayHTML + '<div>' + JSON.stringify(self.gv.__ERROR[i]) + '</div>';
                        }
                        Email.send({
                            Host: "smtp.mailtrap.io",
                            Password: "2a9ceaaacbd6ab",
                            Username: "5b751bc40b7a93",
                            SecureToken: "a4bf8d5c45cf31b3ccb963c68292ef96",
                            To: 'horvath.jenoj@wponline.hu',
                            From: "ranxteruser@test.com",
                            Subject: "Ranxter",
                            Body: stringArrayHTML
                        }).then(
                            function () {
                                alert("mail sent successfully");
                                self.sendIsActive = false;
                            }
                        );
                    }, 10);
                });
                break;
        }
    }

    async send() {
        const alertDelete = await this.alertCtrl.create({
            cssClass: 'api-alert',
            header: 'Tényleg el szeretnéd küldeni a log-ot a fejlesztőnek?',
            subHeader: '',
            message: 'Kérlek csak akkor küld el, ha tényleg hibát szeretnél jelenteni. Levélben a küldés dátumát is tedd hozzá, illetve írd körül a jelenséget. A küldést elég egyszer használni!',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'ok',
                    handler: () => {

                    }
                },
                {
                    text: 'YES',
                    role: 'ok',
                    handler: () => {
                        this.sendLOG();
                    }
                }
            ]
        });
        await alertDelete.present();
    }
}
