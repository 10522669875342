import {Component, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpInterceptor} from '@angular/common/http';

import {AlertController} from '@ionic/angular';

import {Router} from '@angular/router';
import {GlobalVariables} from '../../../global-variables';
import {async} from "rxjs/internal/scheduler/async";
import {ApiConfiguration} from "../configuration";
import {ApiService} from "../api.service";

@Injectable({
    providedIn: 'root'
})
export class ApiKaKa {

    baseURL: string = '';

    token: string = '';

    data: any;
    headers: any;
    headersJSON: any;

    constructor(private httpClient: HttpClient, private router: Router, public gv: GlobalVariables, public alertController: AlertController, private apiConfig: ApiConfiguration, private apiService: ApiService) {

        this.baseURL = this.gv.__BASE_URL;
        this.token = this.gv.__TOKEN;
        this.headers = {
            headers: new HttpHeaders(this.apiConfig.headers)
        };
        this.headersJSON = this.apiConfig.headersJSON;

    }

    /**
     * Get ad pages
     *
     * @param payload
     * @param success
     * @param limit
     * @param offset
     * @param order
     */
    getKa(payload: any, success: any, id:string) {
        const self = this;

        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/websites/537/blocks?envelope=true' + '&code=' + id + '&locale=' + this.gv.__GLOBAL_LANG, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });

    }
}
