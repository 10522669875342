import {Injectable, NgZone} from '@angular/core';
import {GlobalVariables} from "../../global-variables";

import {Facebook, FacebookLoginResponse} from '@awesome-cordova-plugins/facebook/ngx';
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Location} from "@angular/common";

declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class SocialApiService {

    public auth2: any;

    constructor(
        public zone: NgZone,
        public fb: Facebook,
        public gv: GlobalVariables,
        public _location: Location,
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {

    }

    appleLibrary(): void {
        const self = this;
        var script = document.createElement('script');
        script.onload = function () {
            (<any>window.AppleID).auth.init({
                clientId: 'com.ranxter.signin',
                scope: 'name email',
                redirectURI: 'https://ranxter.app/login-gate',
                usePopup: true
            });
        };
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

        document.head.appendChild(script);
    }

    appleLibraryOnAndroid(): void {
        const self = this;

        var script = document.createElement('script');
        script.onload = function () {
            (<any>window.AppleID).auth.init({
                clientId: 'com.ranxter.signin',
                scope: 'name email',
                redirectURI: 'https://ranxter.app/auth-apple-iap',
                usePopup: false
            });
        };
        script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

        document.head.appendChild(script);
    }

    fbLibrary(): void {
        (window as any).fbAsyncInit = function () {
            window['FB'].init({
                appId: '818075875422606',
                autoLogAppEvents: true,
                xfbml: true,
                cookie: true,
                version: 'v9.0'
            });
            window['FB'].AppEvents.logPageView();
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    /**
     * March 31, 2023 - visszavonásra kerül ez
     *
     * We are discontinuing the Google Sign-In JavaScript Platform Library for web. Beginning April 30th, 2022 new web applications must use the Google Identity Services library, existing web apps may continue using the Platform Library until the March 31, 2023 deprecation date. For authentication and user sign-in, use the new Google Identity Services SDKs for both Web and Android instead.
     */
    gLibrary() {
        const self = this;
        if (window['googleSDKLoaded'] == undefined) {
            this.zone.run(() => {
                window['googleSDKLoaded'] = () => {
                    window['gapi'].load('auth2', () => {
                        this.auth2 = window['gapi'].auth2.init({
                            client_id: '364064723493-588vm906qjvbjj5jagv7g4c9mmojq94d.apps.googleusercontent.com',
                            scope: 'https://www.googleapis.com/auth/userinfo.profile',
                        });
                    });
                }
                (function (d, s, id) {
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {
                        return;
                    }
                    js = d.createElement(s);
                    js.id = id;
                    js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'google-jssdk'));
            });
        } else {
            this.auth2 = window['gapi'].auth2.init({
                client_id: '364064723493-588vm906qjvbjj5jagv7g4c9mmojq94d.apps.googleusercontent.com',
                scope: 'https://www.googleapis.com/auth/userinfo.profile',
            });
        }
    }

    async loginApple() {
        try {
            const data = await (<any>window.AppleID).auth.signIn();
            console.log('APPLE DATA', data);
            return data;
        } catch (error) {
            console.error({error});
        }
    }

    shareNative(id: number, type: string): void {
        const self = this;
        this.fb.getLoginStatus().then(
            (e) => {
                console.log('E', e);
                if (e.status !== 'connected') {
                    self.fb.login([])
                        .then(function (response: FacebookLoginResponse) {
                            self.shareDialogOnNativePlatform(id, type);
                        })
                        .catch(e => console.log('Error logging into Facebook', e));
                } else {
                    self.shareDialogOnNativePlatform(id, type);
                }
            },
            (f) => {
                console.log('F', f);
            }
        );
    }

    shareDialogOnNativePlatform(id: number, type: string): void {
        const self = this;
        this.fb.showDialog({
            method: 'share',
            href: this.gv.__HOST_URL + '/' + this.gv.__GLOBAL_LANG.substr(0, 2) + '/share/' + type + '/' + id,
        }).then((e) => {
            self._location.back();
        });
    }

    shareWeb(id: number, type: string): void {
        const self = this;
        console.log(id, type)
        window['FB'].ui({
            method: 'share',
            href: this.gv.__HOST_URL + '/' + this.gv.__GLOBAL_LANG.substr(0, 2) + '/share/' + type + '/' + id,
        }, function (response) {
            self._location.back();
        });
    }

    /**
     * @deprecated
     *
     */
    async alertLoginLogoutBox(id: number, type: string) {
        const self = this;
        const alert = await this.alertCtrl.create({
            cssClass: 'alert-modal',
            header: this.translate.instant('Share on Facebook'),
            message: this.translate.instant('Logout account or share on your feed'),
            buttons: [
                {
                    text: this.translate.instant('Logout'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        self.fb.logout();
                    }
                }, {
                    text: this.translate.instant('Share'),
                    handler: () => {
                        self.shareDialogOnNativePlatform(id, type);
                    }
                }
            ]
        });

        await alert.present();
    }

}
