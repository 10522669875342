export class GlobalVariables {

    /**
     * ERROR
     */
    __ERROR = [];

    /**
     * base url
     */
    __BASE_URL = 'https://ranxter.app/api/v1/companies/389';
    __BASE_DEV_URL = 'https://ranxter.dev.wpo.hu/api/v1/companies/389';

    /**
     * host url
     */
    __HOST_URL = 'https://ranxter.app';
    __HOST_DEV_URL = 'https://dev.ranxter.app';

    /**
     * self image server
     */
    __SELF_IMAGE_SERVER = 'https://ranxter.app/api/v1/companies/389/images'

    /**
     * TMDB image server url
     */
    __IMAGE_API_URL = 'https://image.tmdb.org/t/p';

    /**
     * user auth
     */
    __TOKEN = '';

    /**
     * user profile ID
     */
    __USER_PROFILE_ID:any;

    /**
     * SAFE URL-s
     */
    __SAFE_URLS = [
        'login-gate',
        'registration-step2'
    ]

    /**
     * language
     */
    __GLOBAL_LANG = 'en-US';

    /**
     * platform
     */
    __GLOBAL_PLATFORM = '';

    /**
     * operacios rendszere
     */
    __GLOBAL_OS = '';

    /**
     * storage name
     */
    __GLOBAL_STORAGE_NAME = '__rss';

    /**
     * site object
     */
    __SITE = {
        preloadCounter: 0
    }

    /**
     * Subs
     */
    __SUBS: any = [];

    /**
     * Sub előfizetés típusa (CC vagy PP - simple vagy paypal)
     */
    __SUB_TYPE: any = '';

    /**
     * Premium
     */
    __PREMIUM: any = [];

    /**
     * Trial időszak
     */
    __TRIAL: any = [];

    /**
     * Trial lejárt időszak
     */
    __TRIAL_EXPIRED: any = [];

    /**
     * Magától lejáró előfizetés az érvényes előfizetés?
     */
    __IS_NON_RENEWABLE: boolean = false;

    /**
     * Store validator url
     */
    __STORE_VALIDATOR: string = 'https://validator.fovea.cc/v1/validate?appName=com.ranxter.wplzy&apiKey=85187c00-ced7-4bfc-83ff-5469f73435e1\n';

    /**
     * verzió
     */
    __VER: any = '';

    /**
     * last URL
     */
    __LAST_URL: any = [];
}
