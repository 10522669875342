import {Component, Input, OnInit} from '@angular/core';
import {ApiKaKa} from "../../services/api/ka/ka";
import {GlobalVariables} from "../../global-variables";

@Component({
    selector: 'app-ka',
    templateUrl: './ka.component.html',
    styleUrls: ['./ka.component.scss'],
})
export class KaComponent implements OnInit {
    @Input() codes: string;
    @Input() inlinestyle: string;

    _kaContentArray: any = [];
    _kaPicture: any = '';
    _kaUrl: any = '';

    constructor(public apiKaKa: ApiKaKa, public gv: GlobalVariables) {

    }

    ngOnInit() {
        const self = this;
        setTimeout(() => {
            self.getKa();
        }, 100);
    }

    async getKa() {
        await this.apiKaKa.getKa('', function () {

        }, this.codes)
            .then(data => {
                this._kaContentArray = (<any>data).content[0];
                this._kaPicture = (<any>data).content[0].picture.id;
                this._kaUrl = (<any>data).content[0].subtitle;
            });
    }

}
