import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {GlobalVariables} from "../../global-variables";
import {Router} from "@angular/router";

@Component({
  selector: 'app-accordion-t2',
  templateUrl: './accordion-t2.component.html',
  styleUrls: ['./accordion-t2.component.scss'],
})
export class AccordionT2Component implements OnInit {
  @Input() name: string;
  @Input() description: any;
  @Input() type: any;
  @Output() change: EventEmitter<string> = new EventEmitter<string>();

  public isMenuOpen: boolean = false;

  constructor(public gv: GlobalVariables, public router: Router) {

  }

  ngOnInit() {

  }

  public movie(id): void {
    switch (this.type) {
      case 'movie':
        this.router.navigateByUrl('/movie/' + id);
        break;
      case 'tv':
        this.router.navigateByUrl('/tv/' + id);
        break;
    }
  }

  public toggleAccordion(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public broadcastName(name: string): void {
    this.change.emit(name);
  }
}
