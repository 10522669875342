import {HttpHeaders} from "@angular/common/http";

export class ApiConfiguration {
    headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    headersJSON = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    headersTEXT = new HttpHeaders({
        'Content-Type': ''
    });
}
