import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gate',
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.scss'],
})
export class GateComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
