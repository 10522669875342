import {Component, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpInterceptor} from '@angular/common/http';

import {AlertController} from '@ionic/angular';

import {Router} from '@angular/router';
import {GlobalVariables} from '../../../global-variables';
import {async} from "rxjs/internal/scheduler/async";
import {ApiConfiguration} from "../configuration";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth/auth.service";
import {saveAs} from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ApiMeProfiles {

    baseURL: string = '';

    token: string = '';

    data: any;
    headers: any;
    headersJSON: any;

    constructor(private httpClient: HttpClient, private router: Router, public gv: GlobalVariables, public alertController: AlertController, private apiConfig: ApiConfiguration, private apiService: ApiService, public authService: AuthService) {
        this.baseURL = this.gv.__BASE_URL;
        this.gv.__TOKEN = this.authService.getToken();

        this.headers = {
            headers: new HttpHeaders(this.apiConfig.headers)
        };
        this.headersJSON = this.apiConfig.headersJSON;
    }

    getMeProfiles(payload: any, success: any, limit: number, offset: number, order: string, status: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/me/profiles?envelope=true' + '&token=' + this.gv.__TOKEN + '&limit=' + limit + '&offset=' + offset + '&order=' + order + '&status=' + status, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error PROFILE');
                    /**
                     * ---------------------------------------------------
                     * profile errornál kidobjuk az usert
                     * --------------------------------------------
                     */
                    switch (self.gv.__GLOBAL_PLATFORM) {
                        case 'cordova':
                            window.localStorage.removeItem('__r_on');
                            window.localStorage.removeItem('__r_tn');
                            window.localStorage.removeItem('__r_l');
                            window.localStorage.removeItem('__r_tr');
                            self.router.navigateByUrl('/');
                            break;
                        case 'browser':
                            window.localStorage.removeItem('__r_on');
                            window.localStorage.removeItem('__r_tn');
                            window.localStorage.removeItem('__r_l');
                            window.localStorage.removeItem('__r_tr');
                            self.router.navigateByUrl('/');
                            break;
                    }
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getMeExport(payload: any, success: any, profileId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/me/profiles/' + profileId + '/export?envelope=true' + '&token=' + this.gv.__TOKEN + '&locale=' + this.gv.__GLOBAL_LANG)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    downLoadFile(data: any, type: string) {
        console.log(data);
        let blob = new Blob([data], {type: type});
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {

        }
    }
}
