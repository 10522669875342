import {Directive, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[preload]',
    host: {
        '[attr.src]': 'finalImage',
        '[attr.class]': 'finalState'
    }
})
export class ImgPreloadDirective {
    @Input() targetSource: string;

    downloadingImage: any;
    finalImage: any;
    finalState: string = '';

    constructor() {

    }

    ngOnInit() {
        const self = this;
        this.downloadingImage = new Image();
        this.finalState = ' progress ';

        this.downloadingImage.onload = () => {
            this.finalImage = this.targetSource;
            this.finalState = ' loaded ';
        }
        this.downloadingImage.onerror = () => {
            this.finalState = ' loaded-but-error ';
        }
        this.downloadingImage.src = this.targetSource;
    }
}
