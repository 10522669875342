import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreloadComponent} from "./preload/preload.component";
import {AccordionComponent} from "./accordion/accordion.component";
import {IonicModule} from "@ionic/angular";
import {ImgPreloadModule} from "../directive/lazy-image/img-preload.module";
import {AccordionT2Component} from "./accordion-t2/accordion-t2.component";
import {NgxProgressiveImageLoaderModule} from "ngx-progressive-image-loader";
import {KaComponent} from "./ka/ka.component";
import {CookieComponent} from "./cookie/cookie.component";
import {TranslateModule} from "@ngx-translate/core";
import {GateComponent} from "./gate/gate.component";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ImgPreloadModule,
        NgxProgressiveImageLoaderModule,
        TranslateModule
    ],
    declarations: [PreloadComponent, AccordionComponent, AccordionT2Component, KaComponent, CookieComponent, GateComponent],
    exports: [PreloadComponent, AccordionComponent, AccordionT2Component, KaComponent, CookieComponent, GateComponent]
})
export class ModulesModule {
}
