import {Component, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpInterceptor} from '@angular/common/http';

import {AlertController} from '@ionic/angular';

import {Router} from '@angular/router';
import {GlobalVariables} from '../../../global-variables';
import {async} from "rxjs/internal/scheduler/async";
import {ApiConfiguration} from "../configuration";
import {ApiService} from "../api.service";
import {AuthService} from "../../auth/auth.service";
import {saveAs} from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ApiProductsProducts {

    baseURL: string = '';

    token: string = '';

    data: any;
    headers: any;
    headersJSON: any;

    constructor(private httpClient: HttpClient, private router: Router, public gv: GlobalVariables, public alertController: AlertController, private apiConfig: ApiConfiguration, private apiService: ApiService, public authService: AuthService) {
        this.baseURL = this.gv.__BASE_URL;
        this.gv.__TOKEN = this.authService.getToken();

        this.headers = {
            headers: new HttpHeaders(this.apiConfig.headers)
        };
        this.headersJSON = this.apiConfig.headersJSON;
    }

    getProductsProducts(payload: any, success: any, limit: number, offset: number, order: string, status: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/products?envelope=true' + '&token=' + this.gv.__TOKEN + '&limit=' + limit + '&offset=' + offset + '&order=' + order + '&status=1' + '&locale=' + this.gv.__GLOBAL_LANG , payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    postProductsForGetRealPackage(payload: any, success: any, profileId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.post(this.baseURL + '/me/profiles/' + profileId + '/subscriptions/quote?envelope=true' + '&token=' + this.gv.__TOKEN + '&locale=' + this.gv.__GLOBAL_LANG, payload, this.headers)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    postProductsOrder(payload: any, success: any, profileId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.post(this.baseURL + '/me/profiles/' + profileId + '/subscriptions/pay?envelope=true' + '&token=' + this.gv.__TOKEN, payload, this.headers)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getProductsTransactionById(payload: any, success: any, profileId: number, transactionId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/me/profiles/' + profileId + '/transactions/' + transactionId + '?envelope=true' + '&token=' + this.gv.__TOKEN, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getProductsMobileStatus(payload: any, success: any, profileId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/me/profiles/' + profileId + '/subscriptions/mobile?envelope=true' + '&token=' + this.gv.__TOKEN, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getProductsWebStatus(payload: any, success: any, profileId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/me/profiles/' + profileId + '/subscriptions/web?envelope=true' + '&token=' + this.gv.__TOKEN, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    getProductsAllStatus(payload: any, success: any, profileId: number) {
        const self = this;
        return new Promise(resolve => {
            this.httpClient.get(this.baseURL + '/me/profiles/' + profileId + '/subscriptions/all?envelope=true' + '&token=' + this.gv.__TOKEN, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }

    patchProductsWebSubscription(payload: any, success: any, profileId: any, subscriptionId: any) {
        const self = this;

        return new Promise(resolve => {
            this.httpClient.patch(this.baseURL + '/me/profiles/' + profileId + '/subscriptions/' + subscriptionId + '?envelope=true' + '&token=' + this.gv.__TOKEN + '&locale=' + this.gv.__GLOBAL_LANG, payload)
                .subscribe(data => {
                    this.data = data;
                    resolve(this.data);
                    self.apiService.errorMessages(data, success);
                }, function (data) {
                    console.log('error');
                    self.apiService.errorMessages(data, success);
                });
        });
    }
}
