import {Component, Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {AuthService} from "../auth/auth.service";
import {GlobalVariables} from "../../global-variables";
import {LogService} from "../log/log.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(public alertController: AlertController, public router: Router, public location: Location, public authService: AuthService, public gv: GlobalVariables, public log: LogService, public translate: TranslateService) {
        if (!environment.production) {
            this.gv.__BASE_URL = this.gv.__BASE_DEV_URL;
            this.gv.__HOST_URL = this.gv.__HOST_DEV_URL;
        }
    }

    async errorMessages(errorData, success) {
        let
            key,
            errorArray = [];
        const self = this;
        let secondaryErrorMessage = '';
        let secondaryButtonIsActive = true;

        /**
         * LOG
         */
        this.log.serverError(errorData);
console.log('e', errorData)
        /**
         * full invalid or bad request (we have status code error)
         * -------------------------------------------------------
         */
        if (errorData.status !== undefined) {
            console.log('ITT', errorData.status)
            /** error code */
            switch (errorData.status) {
                case 401:
                    //this.router.navigate(['/beallitasok/kijelentkezes']);
                    break;
                case 403:
                    // if (window.sessionStorage.getItem('__r_ur') == null) {
                    //
                    //     if (this.gv.__SAFE_URLS.indexOf(window.location.href.replace(/^(?:\/\/|[^/]+)*\//, '')) > -1) {
                    //
                    //     } else {
                    //         window.sessionStorage.setItem('__r_ur', window.location.href.replace(/^(?:\/\/|[^/]+)*\//, ''));
                    //     }
                    // }
                    // this.router.navigate(['/home']);

                    this.router.navigate(['/home']);
                    secondaryErrorMessage = 'Now, we redirect to the LOGIN page!';
                    secondaryButtonIsActive = false;
                    return
                    break;
            }
            /** error in content */
            const alert = await this.alertController.create({
                cssClass: 'api-alert request-error',
                header: this.translate.instant('Attention'),
                subHeader: '',
                message: errorData.error.content.error.message + '<br>' + secondaryErrorMessage,
                buttons: [
                    // {
                    //     text: 'Go to last page',
                    //     role: 'goback',
                    //     handler: () => {
                    //         if (secondaryButtonIsActive)
                    //             this.location.back();
                    //     }
                    // },
                    {
                        text: 'OK',
                        role: 'ok',
                        handler: () => {

                        }
                    }]
            });

            await alert.present();
        }

        /**
         * valid request but we got error (status code OK, but not valid)
         * -------------------------------------------------------
         */
        if (errorData.status == undefined && errorData.content.isValid === false) {
            let str = '';
            for (const [key, value] of Object.entries(errorData.content.messages)) {
                let iKey = key;
                if (typeof value === 'object') {
                    for (const [key, value] of Object.entries(errorData.content.messages[iKey])) {
                        str = str + '<strong>' + iKey + '</strong> ' + `${value}` + '<br>';
                    }
                } else {
                    str = str + '<strong>' + iKey + '</strong> ' + `${value}` + '\n';
                }

            }

            /** error in content */
            const alert = await this.alertController.create({
                cssClass: 'api-alert request-error',
                header: this.translate.instant('Attention'),
                subHeader: '',
                message: str,
                buttons: [
                    // {
                    //     text: 'Go to last page',
                    //     role: 'goback',
                    //     handler: () => {
                    //         this.location.back();
                    //     }
                    // },
                    {
                        text: 'OK',
                        role: 'ok',
                        handler: () => {

                        }
                    }]
            });

            await alert.present();
        }
    }
}
