import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {GlobalVariables} from "../../global-variables";
import {Router} from "@angular/router";

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
    @Input() name: string;
    @Input() description: any;
    @Input() isOpen: any;
    @Output() change: EventEmitter<string> = new EventEmitter<string>();

    public isMenuOpen: boolean = false;

    constructor(public gv: GlobalVariables, public router: Router) {

    }

    ngOnInit() {
        if (this.isOpen) {
            this.isMenuOpen = true;
        }
    }

    public crew(id): void {
        this.router.navigateByUrl('/crew-read/' + id);
    }

    public toggleAccordion(): void {
        this.isMenuOpen = !this.isMenuOpen;
    }

    public broadcastName(name: string): void {
        this.change.emit(name);
    }
}
