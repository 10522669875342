import {Component, NgZone} from '@angular/core';
import {InAppPurchase2} from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

import {environment} from '../environments/environment';

import {AlertController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {GlobalVariables} from './global-variables';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {EmailActivatorPage} from "./auth/email-activator/email-activator.page";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {ApiProductsProducts} from "./services/api/products/products";
import {ApiMeProfiles} from "./services/api/me/profiles";
import {LogService} from "./services/log/log.service";
import {SubsService} from "./services/auth/subs.service";
import {filter} from "rxjs/operators";
import {CookieComponent} from "./modules/cookie/cookie.component";

import {SocialApiService} from "./services/socials/social-api.service";

declare let window: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    _language = 'en-US';
    state: number = 0;
    count: number = 1;
    progress: number = 0;
    isTrueApp: boolean = false;
    isProduction: boolean = false;
    isPause: boolean = false;
    pauseTimer: any;

    __cookieIsActive = true;

    constructor(
        public platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        public gv: GlobalVariables,
        public translate: TranslateService,
        public iap2: InAppPurchase2,
        public alertCtrl: AlertController,
        private deeplinks: Deeplinks,
        public router: Router,
        public apiProducts: ApiProductsProducts,
        public meProfiles: ApiMeProfiles,
        private ngZone: NgZone,
        public route: ActivatedRoute,
        public log: LogService,
        public subs: SubsService,
        private socialService: SocialApiService
    ) {
        this.isProduction = environment.production;
        this.gv.__VER = environment.appVersion;

        this.initializeApp();
        this.checkURL();

        /**
         * -----------------------
         * éles cuccai
         * -----------------------------------
         */
        if (this.isProduction) {
            /** bybye console.log */
            console.log = function () {
            }
        }

        // this.subs.checkStoredSubs().subscribe((e) => {
        //     console.log(e)
        // });
    }

    initializeApp() {
        /**
         * add version
         */
        let textHTML =
            '<!-- \n' +
            'RANXTER MOVIE APP \n' +
            '___________________________\n' +
            new Date().getTime() + '_v:' + this.gv.__VER + '\n' +
            '___________________________\n' +
            '-->'

        document.getElementsByTagName('head')[0].innerHTML += textHTML;

        this.log.connect();
        this.platform.ready().then(() => {
            // this.askTrackingPermission();
            // this.readTrackingPermission();
            /**
             * apple 14.5 transparency cookie rész
             */
            // declare var window: {plugins: {
            //         impacTracking: {
            //             canRequestTracking: (callback: (result: boolean) => void) => void,
            //             trackingAvailable: (callback: (result: boolean) => void) => void
            //             requestTracking: (info: string | undefined, callback: (result: boolean) => void, errorCallback: (error: any) => void) => void
            //         }
            //     }};
            // console.log(window, window.ImpacTracking, window.impacTracking)

            if (window.localStorage.getItem('__r_tn') == null || window.localStorage.getItem('__r_tn') == 'null') {
                window.localStorage.removeItem('__r_tn');
                this.init();
                // this.getProfiles();
            } else {
                this.getProfiles();
                /**
                 * figyelni kell hogy alttabol-e
                 */
                this.pauseEvent();
            }
        });
    }

    askTrackingPermission() {
        if (this.platform.is('cordova') && this.platform.is('ios')) {

            if (window.cordova) {
                console.log('trying to request permission ');
                window.cordova.exec(win, fail, 'idfa', "requestPermission", []);
            }
        }

        function win(res) {
            document.getElementById('app-cookie').style.display = 'none';
            window.localStorage.setItem('__r_cook', '1');
            window['ga-disable-G-Y6T61MZGZG'] = true;

            console.log('success disable ' + JSON.stringify(res));
        }

        function fail(res) {
            console.log('fail disable ' + JSON.stringify(res));
        }
    }

    readTrackingPermission() {

        if (this.platform.is('cordova') && this.platform.is('ios')) {

            if (window.cordova) {
                window.cordova.exec(win, fail, 'idfa', "getInfo", []);
            }
        }

        function win(res) {
            console.log('success  read track 1 ' + JSON.stringify(res), (<any>res).trackingPermission);
            if ((<any>res).trackingPermission == 1 || (<any>res).trackingPermission == 2) {
                document.getElementById('app-cookie').style.display = 'none';
                window.localStorage.setItem('__r_cook', '1');
                window['ga-disable-G-Y6T61MZGZG'] = true;
            }
        }

        function fail(res) {
            console.log('fail read track 1 ' + JSON.stringify(res));
        }
    }

    checkURL(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({urlAfterRedirects}: NavigationEnd) => {
                this.gv.__LAST_URL = [...this.gv.__LAST_URL, urlAfterRedirects];

                if (this.gv.__LAST_URL.length > 50) {
                    this.gv.__LAST_URL.shift();
                }
            });
    }

    ngDoCheck(): void {
        const preloadChange = this.progress;
        if (preloadChange)
            if (this.count === this.progress)
                this.state = this.count;
    }

    pauseEvent() {
        const self = this;
        this.platform.resume.subscribe((e) => {
            clearTimeout(this.pauseTimer);
        });
        this.platform.pause.subscribe((e) => {
            this.isPause = true;
            this.pauseTimer = setTimeout(() => {
                clearTimeout(self.pauseTimer);
                self.router.navigateByUrl('/login-gate');
            }, 240000);
        });
    }

    init() {
        if (window.localStorage.getItem('__r_l') == null) {
            this._language = this.gv.__GLOBAL_LANG;
            window.localStorage.setItem('__r_l', this._language);
        } else {
            this._language = window.localStorage.getItem('__r_l');
            this.gv.__GLOBAL_LANG = this._language;
        }
        this.translate.setDefaultLang(this.gv.__GLOBAL_LANG);

        /**
         * start
         */
        this.isMobileBrowser();
        this.socialInit();
        this.deepLinkRoute();

        /** külső urlek leválasztása
         * --------------------------
         **/
        if (
            this.platform.url().indexOf('password-change') > -1 ||
            this.platform.url().indexOf('email/activate') > -1 ||
            this.platform.url().indexOf('payment/') > -1 ||
            this.platform.url().indexOf('onboarding') > -1 ||
            this.platform.url().indexOf('404') > -1 ||
            this.platform.url().indexOf('auth-apple-iap') > -1 ||
            this.platform.url().indexOf('auth-apple-close') > -1 ||
            this.platform.url().indexOf('privacy-policy') > -1 ||
            this.platform.url().indexOf('share-movie') > -1 ||
            this.platform.url().indexOf('terms-of-use') > -1) {
            this.isTrueApp = true;

            /** belső url |||||||||
             * --------------------------
             **/
        } else {
            /** premium or not
             * --------------------------
             **/
            console.log('trial app.comp', this.gv.__TRIAL, 'premium app.comp', this.gv.__PREMIUM);
            if (this.gv.__PREMIUM.length > 0) {
                this.isTrueApp = true;
            } else {
                if (this.gv.__TRIAL.length > 0) {
                    /** ha trial alatt van előfizud */
                    if (this.gv.__PREMIUM.length > 0) {
                        this.router.navigateByUrl('/movies');
                    } else {
                        this.isTrueApp = true;
                    }
                } else {
                    this.router.navigateByUrl('/onboarding');
                    this.isTrueApp = true;
                }
            }
        }
        this.statusBar.styleDefault();
        this.splashScreen.hide();
    }

    getProfiles() {
        this.meProfiles.getMeProfiles('', function () {

        }, 1, 0, 'id DESC', 1)
            .then(data => {
                console.log((<any>data).content.length)
                if ((<any>data).content.length == 0) {
                    this.logOut();
                } else {
                    this.gv.__USER_PROFILE_ID = (<any>data).content[0].id;
                    this.progress++;
                    this.checkStoredSubs();
                }

            });
    }

    logOut(): void {
        const self = this;
        switch (this.gv.__GLOBAL_PLATFORM) {
            case 'cordova':
                window.localStorage.removeItem('__r_tn');
                window.localStorage.removeItem('__r_tn');
                window.localStorage.removeItem('__r_on');
                window.localStorage.removeItem('__r_l');
                this.router.navigateByUrl('/onboarding');
                break;
            case 'browser':
                window.localStorage.removeItem('__r_tn');
                window.localStorage.removeItem('__r_tn');
                window.localStorage.removeItem('__r_on');
                window.localStorage.removeItem('__r_l');
                this.router.navigateByUrl('/onboarding');
                break;
        }
    }

    deepLinkRoute() {
        const self = this;
        switch (this.gv.__GLOBAL_OS) {
            case 'android':
                this.deeplinks.route({
                    '/email/activate/:token': EmailActivatorPage,
                }).subscribe(match => {
                    // match.$route - the route we matched, which is the matched entry from the arguments to route()
                    // match.$args - the args passed in the link
                    // match.$link - the full link data
                    console.log('Successfully matched route', match, match.$args);
                    self.ngZone.run(() => {
                        self.router.navigateByUrl('/email/activate/' + (<any>match.$args).token);
                    })
                }, nomatch => {
                    // nomatch.$link - the full link data
                    console.error('Got a deeplink that didn\'t match', nomatch);
                });
                break;
            case 'ios':
                this.deeplinks.route({
                    EmailActivatorPage: '/email/activate/:token',
                }).subscribe(match => {
                    console.log('Success: ', match);
                    const internalPath = `/${match.$route}/${match.$args['id']}`;
                    self.ngZone.run(() => {
                        self.router.navigateByUrl('/email/activate/' + (<any>match.$args).token);
                    });
                }, noMatch => {
                    console.error('Error: ', noMatch, JSON.parse(noMatch));
                });
                break;
        }
    }

    checkStoredSubs() {
        const self = this;

        this.apiProducts.getProductsAllStatus('', () => {
        }, this.gv.__USER_PROFILE_ID)
            .then(data => {
                this.gv.__PREMIUM = [];
                this.gv.__TRIAL = [];
                console.log(Object.values((<any>data).content.purchases));
                let subArray = Object.values((<any>data).content.purchases);
                /**
                 * ez a login és a token miatt kell. Nyilván nem így készült volna, ha nem az app átadása előtt variáljuk fossá
                 * ue. van még: app.components.ts /// login-gate.page.ts /// subscripton-logged-gate.page.ts
                 */
                for (var i in subArray) {
                    if (!(<any>subArray)[i].isExpired && (<any>subArray)[i].isTrialPeriod == 0) {
                        this.gv.__PREMIUM.push({
                            type: (<any>subArray)[i].platform,
                            data: (<any>subArray)[i]
                        });
                        if ((<any>subArray)[i].renewalIntent && (<any>subArray)[i].platform == 'web') {

                        }
                    }
                    if (!(<any>subArray)[i].isExpired && (<any>subArray)[i].isTrialPeriod == null) {
                        this.gv.__PREMIUM.push({
                            type: (<any>subArray)[i].platform,
                            data: (<any>subArray)[i]
                        });
                        if ((<any>subArray)[i].renewalIntent && (<any>subArray)[i].platform == 'web') {

                        }
                    }
                    if ((<any>subArray)[i].isTrialPeriod == 1 && (<any>subArray)[i].isExpired == false) {
                        if (this.gv.__PREMIUM.length == 0) {
                            this.gv.__TRIAL.push({
                                type: (<any>subArray)[i].platform,
                                data: (<any>subArray)[i]
                            });
                        }
                    }
                }
                /** LEJÁRT TRIAL-e */
                if (Object.values((<any>data).content.purchases).length < 2 && this.gv.__TRIAL.length == 0)
                    this.gv.__TRIAL_EXPIRED.push('nem lejárt trial')

                console.log('THIS__TRIAL', this.gv.__TRIAL);
                console.log('THIS__PREMIUM', this.gv.__PREMIUM);
                console.log('THIS__TRIAL_VOLT', this.gv.__TRIAL_EXPIRED);
                this.init();
            });
    }

    isMobileBrowser() {
        switch (true) {
            case (this.platform.is('cordova')):
                this.gv.__GLOBAL_PLATFORM = 'cordova';
                /** melyik os? **/
                if (this.platform.is('ios')) {
                    this.gv.__GLOBAL_OS = 'ios';
                }
                if (this.platform.is('android')) {
                    this.gv.__GLOBAL_OS = 'android';
                }
                break;
            default:
                this.gv.__GLOBAL_PLATFORM = 'browser';
                break;
        }
    }

    socialInit(): void {
        if (window.location.href.match('auth-apple-iap')) {
            this.socialService.appleLibraryOnAndroid();
            return;
        }

        switch (this.gv.__GLOBAL_PLATFORM) {
            case 'browser':
                this.socialService.fbLibrary();
                this.socialService.gLibrary();
                this.socialService.appleLibrary();
                break;
        }

        switch (this.gv.__GLOBAL_OS) {
            case 'android':

                break;
        }
    }
}
