import {NgModule, ErrorHandler, Injectable} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {GlobalVariables} from './global-variables';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
    HttpClientModule,
    HttpClient,
    HttpRequest,
    HttpInterceptor,
    HttpHandler,
    HTTP_INTERCEPTORS
} from '@angular/common/http';

import {ToolbarPageModule} from "./partial/toolbar/toolbar.module";

import {ApiConfiguration} from "./services/api/configuration";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {IonicStorageModule} from '@ionic/storage';
import {SecureStorage} from "@ionic-native/secure-storage/ngx";

import {NgxProgressiveImageLoaderModule, IImageLoaderOptions} from 'ngx-progressive-image-loader';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';

/** cookie */
import {CookieService} from "ngx-cookie-service";

/** file */
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';

/** premissions */
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';

/** inapp */
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

/** apple sign in */
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';


/** native */
import {MediaCapture, MediaFile, CaptureError, CaptureImageOptions} from '@ionic-native/media-capture/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import {GooglePlus} from '@awesome-cordova-plugins/google-plus/ngx';
import {EmailComposer} from '@ionic-native/email-composer/ngx';

/** lightbox */
import {LightboxModule} from 'ngx-lightbox';

/** sentry */
//import * as Sentry from "sentry-cordova";
import {ModulesModule} from "./modules/modules.module";
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {LogService} from "./services/log/log.service";
// Sentry.init({
//     dsn: 'https://70f9a4ccf5c0484681aa12224f16f7a6@sentry.wponline.hu/6',
//     environment: "production"
// });
// export class SentryIonicErrorHandler extends ErrorHandler {
//     handleError(error) {
//         super.handleError(error);
//         try {
//             Sentry.captureException(error.originalError || error);
//         } catch (e) {
//             console.error(e);
//         }
//     }
// }

import { Device } from '@awesome-cordova-plugins/device/ngx';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './res/i18n/', '.json');
}

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
            }
        });
        return next.handle(authReq);
    }
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'md',
            swipeBackEnabled: true
        }),
        NgxProgressiveImageLoaderModule.forRoot(<IImageLoaderOptions>{
            // rootMargin: '30px',
            // threshold: 0.1,
            // filter: 'blur(0px) drop-shadow(0 0 0.75rem crimson)',
            imageRatio: 2 / 3,
            placeholderImageSrc:
                'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjQwMCIgdmlld0JveD0iMCAwIDMwMCA0MDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIzMDAiIGhlaWdodD0iNDAwIiBmaWxsPSIjMEQxOTM0Ii8+CjxyZWN0IHdpZHRoPSIzMDAiIGhlaWdodD0iNDAwIiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWwpIiBmaWxsLW9wYWNpdHk9IjAuMzMiLz4KPHBhdGggZD0iTTIxNy42MTggNzUuODIwOEwxNTAuODAxIDlMODMuOTgzMyA3NS44MjA4TDE1MC44MDEgMTQyLjY0MkwyMTcuNjE4IDc1LjgyMDhaTTE1MC44MDEgMTE0LjI2NkwxMTIuMzU4IDc1LjgyMDhMMTUwLjgwMSAzNy4zNzZMMTg5LjI0NCA3NS44MjA4TDE1MC44MDEgMTE0LjI2NloiIGZpbGw9InVybCgjcGFpbnQxX3JhZGlhbCkiLz4KPHBhdGggZD0iTTEgMzYuNzU4VjkxLjY3OTJMMTIzLjA0MSAyMTQuMDMyTDEgMzM2LjA3OVYzOTFMMTUwLjUgMjQxLjQ5MkwzMDAgMzkxVjMzNi4wNzlMMTc3Ljk1OSAyMTQuMDMyTDMwMCA5MS42NzkyVjM2Ljc1OEwxNTAuNSAxODYuMjY2TDEgMzYuNzU4WiIgZmlsbD0idXJsKCNwYWludDJfcmFkaWFsKSIvPgo8ZGVmcz4KPHJhZGlhbEdyYWRpZW50IGlkPSJwYWludDBfcmFkaWFsIiBjeD0iMCIgY3k9IjAiIHI9IjEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiBncmFkaWVudFRyYW5zZm9ybT0idHJhbnNsYXRlKDE1MCAyMDApIHJvdGF0ZSg5MCkgc2NhbGUoMjY4LjkxOSAyMDEuNjg5KSI+CjxzdG9wIHN0b3AtY29sb3I9IiMyMzQ1OTEiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMEMxODMzIi8+CjwvcmFkaWFsR3JhZGllbnQ+CjxyYWRpYWxHcmFkaWVudCBpZD0icGFpbnQxX3JhZGlhbCIgY3g9IjAiIGN5PSIwIiByPSIxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgZ3JhZGllbnRUcmFuc2Zvcm09InRyYW5zbGF0ZSgxNTAuNSAyMDApIHJvdGF0ZSg5MCkgc2NhbGUoMTkxIDE0OS41KSI+CjxzdG9wIHN0b3AtY29sb3I9IiMyNDQ2OTIiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMjQ0NjkyIiBzdG9wLW9wYWNpdHk9IjAiLz4KPC9yYWRpYWxHcmFkaWVudD4KPHJhZGlhbEdyYWRpZW50IGlkPSJwYWludDJfcmFkaWFsIiBjeD0iMCIgY3k9IjAiIHI9IjEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiBncmFkaWVudFRyYW5zZm9ybT0idHJhbnNsYXRlKDE1MC41IDIwMCkgcm90YXRlKDkwKSBzY2FsZSgxOTEgMTQ5LjUpIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzI0NDY5MiIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMyNDQ2OTIiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L3JhZGlhbEdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo='
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ToolbarPageModule,
        LightboxModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        }),
        ModulesModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
    ],
    providers: [
        StatusBar,
        SplashScreen,
        GlobalVariables,
        ApiConfiguration,
        MediaCapture,
        Camera,
        FileTransfer,
        Device,
        File,
        Deeplinks,
        EmailComposer,
        FileOpener,
        InAppBrowser,
        SignInWithApple,
        Facebook,
        AndroidPermissions,
        GooglePlus,
        InAppPurchase2,
        FileUploadModule,
        SecureStorage,
        CookieService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        // {provide: ErrorHandler, useClass: LogService},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheHeadersInterceptor,
            multi: true
        }
        // {provide: ErrorHandler, useClass: SentryIonicErrorHandler}
    ],
    bootstrap: [AppComponent],
    exports: [
        TranslateModule
    ],
})
export class AppModule {
}
