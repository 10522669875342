import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MainHomePageModule} from "./main/main-home/main-home.module";
import {MovieReadPage} from "./main/movies/movie-read/movie-read.page";
import {MainHomePage} from "./main/main-home/main-home.page";

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'init',
        loadChildren: () => import('./init/init.module').then(m => m.InitPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'registration',
        loadChildren: () => import('./auth/registration/registration.module').then(m => m.RegistrationPageModule)
    },
    {
        path: 'registration-step2',
        loadChildren: () => import('./auth/registration-step2/registration-step2.module').then(m => m.RegistrationStep2PageModule)
    },
    {
        path: 'forgot-pw',
        loadChildren: () => import('./auth/forgot-pw/forgot-pw.module').then(m => m.ForgotPwPageModule)
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./auth/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
    },
    {
        path: 'movies',
        loadChildren: () => import('./main/main-home/main-home.module').then(m => m.MainHomePageModule)
    },
    {
        path: 'movie/:id',
        loadChildren: () => import('./main/movies/movie-read/movie-read.module').then(m => m.MovieReadPageModule)
    },
    {
        path: 'tv/:id',
        loadChildren: () => import('./main/tvs/tv-read/tv-read.module').then(m => m.TvReadPageModule)
    },
    {
        path: 'tv/:id/seasons/:seasonid',
        loadChildren: () => import('./main/tvs/tv-seasons/tv-seasons.module').then(m => m.TvSeasonsPageModule)
    },
    {
        path: 'tv/:id/seasons/:seasonid/episode/:episodeid',
        loadChildren: () => import('./main/tvs/tv-episode/tv-episode.module').then(m => m.TvEpisodePageModule)
    },
    {
        path: 'crew/:type/:id/:subType',
        loadChildren: () => import('./main/crew/crew/crew.module').then(m => m.CrewPageModule)
    },
    {
        path: 'crew-read/:id',
        loadChildren: () => import('./main/crew/crew-read/crew-read.module').then(m => m.CrewReadPageModule)
    },
    {
        path: 'toolbar',
        loadChildren: () => import('./partial/toolbar/toolbar.module').then(m => m.ToolbarPageModule)
    },
    {
        path: 'recommended/:id',
        loadChildren: () => import('./main/recommended/recommended.module').then(m => m.RecommendedPageModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile-home/profile-home.module').then(m => m.ProfileHomePageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('./profile/settings/settings.module').then(m => m.SettingsPageModule)
    },
    {
        path: 'settings/personal',
        loadChildren: () => import('./profile/personal/personal.module').then(m => m.PersonalPageModule)
    },
    {
        path: 'language',
        loadChildren: () => import('./profile/language/language.module').then(m => m.LanguagePageModule)
    },
    {
        path: 'feedback',
        loadChildren: () => import('./profile/feedback/feedback.module').then(m => m.FeedbackPageModule)
    },
    {
        path: 'privacy',
        loadChildren: () => import('./profile/privacy/privacy.module').then(m => m.PrivacyPageModule)
    },
    {
        path: 'delete',
        loadChildren: () => import('./profile/delete/delete.module').then(m => m.DeletePageModule)
    },
    {
        path: 'rate/:type/:id/:season/:episode/:episodeFullID',
        loadChildren: () => import('./rate/rate-home/rate-home.module').then(m => m.RateHomePageModule)
    },
    {
        path: 'mylist',
        loadChildren: () => import('./mylist/mylist-home/mylist-home.module').then(m => m.MylistHomePageModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search-main/search-main.module').then(m => m.SearchMainPageModule)
    },
    {
        path: 'search-list',
        loadChildren: () => import('./search/search-list/search-list.module').then(m => m.SearchListPageModule)
    },
    {
        path: 'stat',
        loadChildren: () => import('./stat/stat-home/stat-home.module').then(m => m.StatHomePageModule)
    },
    {
        path: 'rankings',
        loadChildren: () => import('./rankings/rankings-home/rankings-home.module').then(m => m.RankingsHomePageModule)
    },
    {
        path: 'subscription',
        loadChildren: () => import('./auth/subscription/subscription.module').then(m => m.SubscriptionPageModule)
    },
    {
        path: 'stat-movie',
        loadChildren: () => import('./stat/stat-movie/stat-movie.module').then(m => m.StatMoviePageModule)
    },
    {
        path: 'stat-tv',
        loadChildren: () => import('./stat/stat-tv/stat-tv.module').then(m => m.StatTvPageModule)
    },
    {
        path: 'subscription-mob-gate',
        loadChildren: () => import('./auth/subscription-mob-gate/subscription-mob-gate.module').then(m => m.SubscriptionMobGatePageModule)
    },
    {
        path: 'page/:slug',
        loadChildren: () => import('./page/page.module').then(m => m.PagePageModule)
    },
    {
        path: 'subscription-form',
        loadChildren: () => import('./auth/subscription-form/subscription-form.module').then(m => m.SubscriptionFormPageModule)
    },
    {
        path: 'subscription-trial',
        loadChildren: () => import('./auth/subscription-trial/subscription-trial.module').then(m => m.SubscriptionTrialPageModule)
    },
    {
        path: 'email/activate/:token',
        loadChildren: () => import('./auth/email-activator/email-activator.module').then(m => m.EmailActivatorPageModule)
    },
    {
        path: 'payment/:transactionId/:hash',
        loadChildren: () => import('./auth/subscription-finish-redirect/subscription-finish-redirect.module').then(m => m.SubscriptionFinishRedirectPageModule)
    },
    {
        path: 'registration-finish',
        loadChildren: () => import('./auth/registration-step3/registration-step3.module').then(m => m.RegistrationStep3PageModule)
    },
    {
        path: 'login-gate',
        loadChildren: () => import('./auth/login-gate/login-gate.module').then(m => m.LoginGatePageModule)
    },
    {
        path: 'subscription-gate',
        loadChildren: () => import('./auth/subscription-logged-gate/subscription-logged-gate.module').then(m => m.SubscriptionLoggedGatePageModule)
    },
    {
        path: 'password-change/:hash',
        loadChildren: () => import('./auth/changed-pw/changed-pw.module').then(m => m.ChangedPwPageModule)
    },


    {
        path: 'popover-list',
        loadChildren: () => import('./mylist/popover/popover.module').then(m => m.PopoverPageModule)
    },
    {
        path: 'search-thumb',
        loadChildren: () => import('./search/search-thumb/search-thumb.module').then(m => m.SearchThumbPageModule)
    },
    {
        path: 'popover-search',
        loadChildren: () => import('./search/popover/popover.module').then(m => m.PopoverPageModule)
    },
    {
        path: 'registration-social',
        loadChildren: () => import('./auth/registration-social/registration-social.module').then(m => m.RegistrationSocialPageModule)
    },
    {
        path: 'share-movie/:id',
        loadChildren: () => import('./outside/share-movie/share-movie.module').then(m => m.ShareMoviePageModule)
    },
    {
        path: 'share-tv/:id',
        loadChildren: () => import('./outside/share-tv/share-tv.module').then(m => m.ShareTvPageModule)
    },
    {
        path: 'callback',
        loadChildren: () => import('./callback/callback.module').then(m => m.CallbackPageModule)
    },
    {
        path: '404',
        loadChildren: () => import('./notfound/notfound.module').then(m => m.NotfoundPageModule)
    },
    {
        path: 'auth-apple-iap',
        loadChildren: () => import('./auth/auth-apple-iap/auth-apple-iap.module').then(m => m.AuthAppleIapPageModule)
    },
    {
        path: 'auth-apple-gate',
        loadChildren: () => import('./auth/auth-apple-gate/auth-apple-gate.module').then(m => m.AuthAppleGatePageModule)
    },
    {
        path: 'auth-apple-close',
        loadChildren: () => import('./auth/auth-apple-close/auth-apple-close.module').then(m => m.AuthAppleClosePageModule)
    },
    {path: '', redirectTo: '/onboarding', pathMatch: 'full'},
    {path: '**', redirectTo: '/404'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
