import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Platform} from "@ionic/angular";
import {GlobalVariables} from "../../global-variables";

@Component({
    selector: 'app-email-activator',
    templateUrl: './email-activator.page.html',
    styleUrls: ['./email-activator.page.scss'],
})
export class EmailActivatorPage implements OnInit {
    state: number = 0;
    count: number = 0;
    progress: number = 0;

    constructor(
        public router: Router,
        public platform: Platform,
        public gv: GlobalVariables,
        private route: ActivatedRoute
    ) {

    }

    ngOnInit() {
        if(this.platform.is('mobileweb')){
            window.location.href = 'ranxter://ranxter.app/email/activate/' +          this.route.snapshot.paramMap.get('token');
        }
    }

    next(): void {
        this.router.navigate(['/home', {activate: true}]);
    }

}
