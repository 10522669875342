import {Component, Injectable, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {EventEmitter} from "events";

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.page.html',
    styleUrls: ['./toolbar.page.scss'],
})
@Injectable()
export class ToolbarPage implements OnInit {
    @Input() pageName;

    constructor(public router: Router) {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {

    }

    ionViewDidEnter() {

    }

    home() {
        this.router.navigateByUrl('/movies');
    }

    mylist() {
        this.router.navigateByUrl('/mylist');
    }

    rankings(){
        this.router.navigateByUrl('/rankings');
    }

    search() {
        this.router.navigateByUrl('/search');
    }

    stat() {
        this.router.navigateByUrl('/stat');
    }

    profile() {
        this.router.navigateByUrl('/profile');
    }


}
