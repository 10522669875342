import {Component, Input, OnInit, Renderer2, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-preload',
    templateUrl: './preload.component.html',
    styleUrls: ['./preload.component.scss'],
})
export class PreloadComponent implements OnInit {
    @Input() state: number;
    @Input() count: number;

    classState = '';
    fullCount = 0;

    constructor(public renderer: Renderer2) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        const self = this;
        let changeState = changes['state'];
        let changeCount = changes['count'];

        if (changeCount !== undefined)
            this.fullCount = changeCount.currentValue;

        // console.log(changeState.currentValue, this.fullCount)

        switch (true) {
            case (changeState.currentValue === this.fullCount):
                this.classState = 'active';
                setTimeout(() => {
                    self.classState = 'finished';
                }, 300);
                break;
            case (changeState.currentValue !== this.fullCount):
                this.classState = '';
                break;
            default:
                break;
        }

    }

}
