import {Injectable} from '@angular/core';
import {GlobalVariables} from "../../global-variables";
import {Platform} from "@ionic/angular";
import {Observable} from "rxjs";
import {ApiProductsProducts} from "../api/products/products";

@Injectable({
    providedIn: 'root'
})
export class SubsService {

    constructor(private platform: Platform, public gv: GlobalVariables, public apiProducts: ApiProductsProducts) {
    }

    checkStoredSubs() {
        const self = this;
        return new Observable((observer) => {
            this.apiProducts.getProductsAllStatus('', () => {
            }, this.gv.__USER_PROFILE_ID)
                .then(data => {
                    this.gv.__PREMIUM = [];
                    this.gv.__TRIAL = [];
                    console.log(Object.values((<any>data).content.purchases));
                    let subArray = Object.values((<any>data).content.purchases);
                    /**
                     * ez a login és a token miatt kell. Nyilván nem így készült volna, ha nem az app átadása előtt variáljuk fossá
                     * ue. van még: app.components.ts /// login-gate.page.ts /// subscripton-logged-gate.page.ts
                     */
                    for (var i in subArray) {
                        if (!(<any>subArray)[i].isExpired && (<any>subArray)[i].isTrialPeriod == 0) {
                            this.gv.__PREMIUM.push({
                                type: (<any>subArray)[i].platform,
                                data: (<any>subArray)[i]
                            });
                            if ((<any>subArray)[i].renewalIntent && (<any>subArray)[i].platform == 'web') {

                            }
                        }
                        if (!(<any>subArray)[i].isExpired && (<any>subArray)[i].isTrialPeriod == null) {
                            this.gv.__PREMIUM.push({
                                type: (<any>subArray)[i].platform,
                                data: (<any>subArray)[i]
                            });
                            if ((<any>subArray)[i].renewalIntent && (<any>subArray)[i].platform == 'web') {

                            }
                        }
                        if ((<any>subArray)[i].isTrialPeriod == 1 && (<any>subArray)[i].isExpired == false) {
                            if (this.gv.__PREMIUM.length == 0)
                                this.gv.__TRIAL.push({
                                    type: (<any>subArray)[i].platform,
                                    data: (<any>subArray)[i]
                                });
                        }
                    }
                    /** LEJÁRT TRIAL-e */
                    if (Object.values((<any>data).content.purchases).length < 2 && this.gv.__TRIAL.length == 0)
                        this.gv.__TRIAL_EXPIRED.push('nem lejárt trial')

                    console.log('THIS__TRIAL', this.gv.__TRIAL);
                    console.log('THIS__PREMIUM', this.gv.__PREMIUM);
                    console.log('THIS__TRIAL_VOLT', this.gv.__TRIAL_EXPIRED);
                    observer.next(true);
                });
        });
    }
}
