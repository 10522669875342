import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import {ImgPreloadDirective} from "./img-preload.directive";


@NgModule({
    imports: [],
    exports: [ImgPreloadDirective],
    declarations: [ ImgPreloadDirective]
})
export class ImgPreloadModule {}
