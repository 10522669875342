import {Component, OnInit, Input} from '@angular/core';
import {GlobalVariables} from "../../global-variables";
import {Platform} from "@ionic/angular";

@Component({
    selector: 'app-cookie',
    templateUrl: './cookie.component.html',
    styleUrls: ['./cookie.component.scss'],
})
export class CookieComponent implements OnInit {
    active: boolean = false;

    constructor(
        public gv: GlobalVariables,
        public platform: Platform
    ) {

        if (window.localStorage.getItem('__r_cook') !== null) {
            this.active = false;
        } else {
            this.active = true;
        }
    }

    agree() {
        window.localStorage.setItem('__r_cook', '1');
        this.active = false;
    }

    ngOnInit() {
        if(this.platform.is('cordova') && this.platform.is('ios')){
            this.active = false;
        }
    }

}
