import {Injectable} from '@angular/core';
import {SecureStorage, SecureStorageObject} from '@ionic-native/secure-storage/ngx';
import {GlobalVariables} from "../../global-variables";
import {promise} from "selenium-webdriver";
import {Platform} from "@ionic/angular";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private platform: Platform, public secureStorage: SecureStorage, public gv: GlobalVariables) {
    }

    getToken(): any {
        /**
         * check global platform - debug változathoz kell
         * ----------------------------------------------
         */
        if (this.gv.__GLOBAL_PLATFORM.length === 0) {
            switch (true) {
                case (this.platform.is('cordova')):
                    this.gv.__GLOBAL_PLATFORM = 'cordova';
                    break;
                default:
                    this.gv.__GLOBAL_PLATFORM = 'browser';
                    break;
            }
        }
        /**
         * token begyűjtése
         * ----------------------------------------------
         */
        switch (this.gv.__GLOBAL_PLATFORM) {
            case 'cordova':
                if (window.localStorage.getItem('__r_tn') !== null)
                    return window.localStorage.getItem('__r_tn');
                break;
            case 'browser':
                if (window.localStorage.getItem('__r_tn') !== null)
                    return window.localStorage.getItem('__r_tn');
                break;
        }
    }

    /**
     * UNUSED
     * ----------------------------------------------
     */
    getStore(): Promise<any> {
        const self = this;

        return new Promise((resolve, reject) => {
            switch (this.gv.__GLOBAL_PLATFORM) {
                case 'cordova':
                    this.secureStorage.create(this.gv.__GLOBAL_STORAGE_NAME)
                        .then((storage: SecureStorageObject) => {
                            storage.get('_rss_t')
                                .then(
                                    data => {
                                        self.gv.__TOKEN = data;
                                        resolve(true);
                                    },
                                    error => {
                                        reject(false)
                                    }
                                );
                        });
                    break;
                case 'browser':
                    if (window.localStorage.getItem('__r_tn') !== null) {
                        this.gv.__TOKEN = window.localStorage.getItem('__r_tn');
                        resolve(true);
                    } else {
                        reject(false);
                    }
                    break;
            }

        });
    }

    setStore(data): Promise<any> {

        return new Promise((resolve, reject) => {
            if ((<any>data).length > 5) {
                switch (this.gv.__GLOBAL_PLATFORM) {
                    case 'cordova':
                        this.createSecureStorage((<any>data));
                        console.log('SETSTORE', (<any>data));
                        resolve(true);
                        break;
                    case 'browser':
                        window.localStorage.setItem('__r_tn', (<any>data));
                        /** ideiglenesen máshogy megy ez most **/
                        this.gv.__TOKEN = (<any>data);
                        resolve(true);
                        break;
                }
            }
        });
    }

    createSecureStorage(data) {
        this.secureStorage.create(this.gv.__GLOBAL_STORAGE_NAME)
            .then((storage: SecureStorageObject) => {
                console.log('STORAGE', storage);
                storage.set('_rss_t', data)
                    .then(
                        data => console.log('SECURE DATA', data),
                        error => console.log('SECURE ERROR', error)
                    );

            });
    }

}
